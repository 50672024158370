* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primary-text-color: #183b56;
  --secondary-text-color: #577592;
  --accent-color: #2294ed;
  --accent-color-dark: #1d69a3;
}
::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  color: var(--primary-text-color);
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}
li {
  list-style: none;
}

.secondary-button {
  border: 0.5px solid var(--secondary-text-color);

  border-radius: 6px;
  font-weight: 500;

  padding: 8px 10px;
  transition: 0.2s ease-out;
}
.secondary-button:hover {
  border-color: var(--accent-color);
  color: var(--accent-color) !important;
}

.imgnow {
  background-image: url("./assets/images/qau4.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.imgnow::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
}
.content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media screen and (max-width: 992px) {
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 0.7rem;

    .nav-links {
      position: absolute;
      background-color: white;
      left: -100%;
      top: 100%;
      width: 100%;
      padding-block: 20px;
      transition: 0.2s ease-in-out;
    }
  }
}
@media screen and (max-width: 767px) {
  .mobile {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
@media screen and (max-width: 479px) {
  :root {
    --padding-inline-section: 10px;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}
